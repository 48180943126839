import { alpha, Chip, makeStyles, Table, TableBody, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { HeadCell } from '../molecules/Table/HeadCell.jsx';
import { COLUMNS_WIDTH_SETTINGS } from '../../containers/Projects/components/project/Budget/constants.js';
import { BasicTableCell } from '../molecules/Table/BasicTableCell.jsx';
import { MultiAvatar } from '../molecules/MultiAvatar.jsx';
import { palette } from '../../settings/themes/colors.js';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  root: {
    '& th:first-child': {
      width: '380px'
    },
    '& th:nth-child(5)': {
      width: '130px'
    },
    '& th:last-child': {
      width: '130px'
    }
  },
  containerRow: {
    borderBottom: `1px solid ${palette.darkGray20}`
  },
  chip: {
    borderRadius: 8,
    border: `1px solid ${alpha(palette.dimGray, 0.2)}`,
    color: `${palette.white} !important`,
    backgroundColor: palette.captuDataYellow
  }
});

export const TableIndicator = ({ indicators = [], projectId, taskId }) => {
  const classes = useStyles();

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <HeadCell>
            <FormattedMessage id="common.indicator" />
          </HeadCell>
          <HeadCell width={COLUMNS_WIDTH_SETTINGS.disbursementCell}>
            <FormattedMessage id="common.variables" />
          </HeadCell>
          <HeadCell width={COLUMNS_WIDTH_SETTINGS.otherCells}>
            <FormattedMessage id="project.indicator.form.lineBase" />
          </HeadCell>
          <HeadCell width={COLUMNS_WIDTH_SETTINGS.actionCell}>
            <FormattedMessage id="project.formulation.form.goal" />
          </HeadCell>
          <HeadCell>
            <FormattedMessage id="project.indicator.table.frequency" />
          </HeadCell>
          <HeadCell>
            <FormattedMessage id="common.responsible" />
          </HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {indicators.map(
          ({ name, indicatorVariables = [], baseLineHuminized, goalHuminized, measurementFrequency, responsible, id }, index) => (
            <TableRow key={index} className={classes.containerRow}>
              <BasicTableCell>
                <Typography>{<Link to={`/projects/${projectId}/formulation/${taskId}/indicators/${id}`}>{name}</Link>}</Typography>
              </BasicTableCell>
              <BasicTableCell>
                {indicatorVariables.map(({ indicatorVariableType = {} }, index) => (
                  <Typography key={index}>
                    {indicatorVariableType.name}
                    {index < indicatorVariables.length - 1 ? ', ' : ''}
                  </Typography>
                ))}
              </BasicTableCell>
              <BasicTableCell>
                <Typography>{baseLineHuminized}</Typography>
              </BasicTableCell>
              <BasicTableCell>
                <Typography>{goalHuminized}</Typography>
              </BasicTableCell>
              <BasicTableCell>
                <Typography>
                  <FormattedMessage
                    id={`project.indicator.details.frequencyMeasure.${measurementFrequency}`}
                    defaultMessage={measurementFrequency}
                  />
                </Typography>
              </BasicTableCell>
              <BasicTableCell>
                <MultiAvatar names={[responsible.fullName]} />
              </BasicTableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

TableIndicator.propTypes = {
  indicators: PropTypes.array,
  projectId: PropTypes.number,
  taskId: PropTypes.number
};
