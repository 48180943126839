import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformSnake2Camel } from './utils';

export const useCreateIndicatorProgress = config =>
  useMutation(async ({ indicatorId, indicatorProgress }) => {
    const { effective_date, description, value, documents = [] } = indicatorProgress;

    const formData = new FormData();
    formData.append('indicator_progress[effective_date]', effective_date);
    formData.append('indicator_progress[description]', description);
    formData.append('indicator_progress[value]', value);

    documents.forEach(item => {
      formData.append(`indicator_progress[documents][][name]`, item.name);
      formData.append(`indicator_progress[documents][][file]`, item.file);
      formData.append(`indicator_progress[documents][][category_ids][]`, JSON.stringify(item.categoryIds[0]));
    });

    const axiosResult = await getApi().post(`/indicators/${indicatorId}/indicator_progresses`, formData);
    return axiosResult.data;
  }, config);

export const useGetIndicatorProgresses = indicatorId => {
  const queryFn = async context => {
    const [indicatorId] = context.queryKey;
    const result = await getApi().get(`/indicators/${indicatorId}/indicator_progresses`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [indicatorId, 'GET_IndicatorProgresses'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
