import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export const TextFormField = ({ onChange = () => {}, schema, initialValidation = true, ...props }) => {
  const value = useRef(props.defaultValue);
  const [error, setError] = useState();

  const validateSchema = () => {
    if (!schema || !schema.validate) return;

    schema
      .validate(value.current)
      .then(() => {
        setError();
      })
      .catch(err => {
        setError(err.message);
      });
  };

  useEffect(() => {
    if (initialValidation) validateSchema();
  }, [initialValidation]);

  const handlerChange = e => {
    value.current = e.target.value;
    onChange(e.target.value);
    validateSchema();
  };

  return <TextField {...props} error={!!error} helperText={error} onChange={handlerChange} />;
};

TextFormField.propTypes = {
  schema: PropTypes.object
};
