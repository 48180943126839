import React from 'react';
import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormField } from '../atoms/FormField';

export const MappingFormFields = ({ fields, getFieldProps, errors = {}, spacing = 2 }) => (
  <Grid container spacing={spacing}>
    {fields.map(({ xs = 4, ...field }, index) => (
      <Grid item xs={xs} key={`field-${field.name}-${index}`}>
        <FormField key={field.name} {...getFieldProps(field.name)} {...field} errors={errors[field.name]} />
      </Grid>
    ))}
  </Grid>
);

MappingFormFields.propTypes = {
  fields: PropTypes.array.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  errors: PropTypes.object,
  spacing: PropTypes.number
};
