import {
  Box,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  MuiThemeProvider,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HeaderContent } from '../molecules/HeaderContent';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { BodyContent } from '../molecules/BodyContent';
import { palette } from '../../settings/themes/colors';
import { useDeleteIndicator, useGetDetailIndicatorVariables, useUpdateIndicator } from '../../queries/queryIndicators';
import { FormattedMessage, useIntl } from 'react-intl';
import Breadcrumb from '../breadcrumb';
import { LinkButton } from '../atoms/LinkButton';
import { ConfirmationModal } from '../molecules/ConfirmationModal';
import PropTypes from 'prop-types';
import { useNotificationContext } from './Notification/NotificationContext';
import { IndicatorModalForm } from './IndicatorModalForm';
import Button from '../button';
import { RegisterProgressIndicatorModal } from './RegisterProgressIndicatorModal';
import { Card } from '../atoms/Card';
import { IndicatorDetailTable } from '../../containers/Projects/IndicatorDetailTable';
import { BoxContainer } from '../atoms/BoxContainer';
import { useGetIndicatorProgresses } from '../../queries/queryIndicatorProgresses';

const useStyles = makeStyles({
  root: {
    color: palette.dimGray
  },
  cardContainer: {
    padding: lightThemeV2.spacing(4),
    paddingBottom: lightThemeV2.spacing(12)
  },
  containerSubtitle: {
    display: 'flex',
    color: palette.dimGray,
    gap: 4
  },
  divider: {
    color: palette.dimGray,
    marginTop: lightThemeV2.spacing(2),
    marginBottom: lightThemeV2.spacing(2)
  },
  progressBar: {
    gap: lightThemeV2.spacing(2),
    '& *[class*=MuiLinearProgress-root]': {
      borderRadius: '4px',
      height: '8px'
    },
    '& *[class*="MuiLinearProgress-bar1Determinate"]': {
      backgroundColor: palette.captuDataYellow
    }
  }
});

const DetailIndicatorComponent = ({ history, match }) => {
  const classes = useStyles();

  const { messages: intlMessages } = useIntl();
  const { showSuccessNotification } = useNotificationContext();
  const { data: detailIndicator, isLoading, refetch } = useGetDetailIndicatorVariables(match.params.taskId, match.params.idIndicator);
  const { data: indicatorProgresses = [], refetch: refetchIndicatorProgresses } = useGetIndicatorProgresses(match.params.idIndicator);
  const [openEditModal, setOpenEditModal] = useState(false);
  const { mutateAsync: updateIndicator } = useUpdateIndicator();
  const { mutateAsync: deleteIndicator } = useDeleteIndicator({
    onSuccess: () => {
      history.goBack();
      showSuccessNotification(intlMessages['project.indicator.message.delete.success'], `${detailIndicator.name}`);
    }
  });
  const [openConfirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [openRecordProgressModal, setOpenRecordProgressModal] = useState(false);
  const handleDeleteIndicator = () => {
    deleteIndicator({ taskId: match.params.taskId, id: match.params.idIndicator });
  };

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  const {
    indicatorVariables,
    baseLine,
    calculationEquation,
    measurementUnit,
    measurementFrequency,
    verificationMeans,
    informationSource,
    researchQuestions,
    dataCollectionMethodology,
    goal,
    name
  } = detailIndicator;

  const renderMultilineText = text => {
    if (!text) return null;

    return text.split('\n').map((line, index) => <Typography key={index}>{line}</Typography>);
  };

  const breadscrumb = [
    {
      name: intlMessages['common.actionHelp'],
      link: '/'
    },
    {
      name: `${intlMessages['common.project']}s`,
      link: '/projects'
    },
    {
      name: intlMessages['project.formulation'],
      link: `/projects/${match.params.projectId}?tab=5`
    },
    {
      name: intlMessages['common.indicator'],
      link: `/projects/${match.params.projectId}/formulation/${match.params.taskId}/indicators/${match.params.idIndicator}`
    }
  ];

  const units = 1;

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <HeaderContent
        tagtitle={<FormattedMessage id="common.indicator" />}
        title={name}
        breadcrumb={<Breadcrumb items={breadscrumb} />}
        subtitleBar={
          <Grid container direction="row" alignItems="center" className={classes.progressBar}>
            <p style={{ color: palette.dimGray }}>
              <FormattedMessage id="mainContractStory.current_status" />
            </p>
            <Grid item xs={8}>
              <LinearProgress variant="determinate" value={0} />
            </Grid>
            <p style={{ color: palette.dimGray }}>
              {units} <FormattedMessage id="common.units" />
            </p>
          </Grid>
        }
      >
        <LinkButton style={{ alignItems: 'end' }} onClick={() => setOpenEditModal(true)}>
          <FormattedMessage id="common.edit" />
        </LinkButton>
        <LinkButton style={{ alignItems: 'end' }} onClick={() => setConfirmDeleteModal(true)}>
          <FormattedMessage id="common.delete" />
        </LinkButton>
        <Button style={{ backgroundColor: palette.cerulean }} variant="contained" onClick={() => setOpenRecordProgressModal(true)}>
          <FormattedMessage id="project.indicator.details.register.advanceButton" />
        </Button>
      </HeaderContent>
      <BodyContent component={BoxContainer}>
        <Card>
          <CardContent>
            <Grid container spacing={4} className={classes.root}>
              <Grid item xs={12}>
                {' '}
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.message.variables.helpertextTitle']}</Typography>
              </Grid>
              <Grid item xs={12}>
                {indicatorVariables.length > 0 ? (
                  indicatorVariables.map((variable, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography variant={'body2'}>{(variable.indicatorVariableType || {}).name || ''}</Typography>
                      <Typography>{variable.description}</Typography>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography>
                      <FormattedMessage id="project.indicator.noVariables" />
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.lineBase']}: </Typography>
                <Typography>
                  {baseLine} {measurementUnit}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.unitMeasurement']}: </Typography>
                <Typography>{measurementUnit}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{intlMessages['project.formulation.form.goal']}: </Typography>
                <Typography>
                  {goal} {measurementUnit}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.calulationForm']}: </Typography>
                <Typography>{calculationEquation}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.frequencyMeasure']}: </Typography>
                <Typography>
                  <FormattedMessage
                    id={`project.indicator.details.frequencyMeasure.${measurementFrequency}`}
                    defaultMessage={measurementFrequency}
                  />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {''}
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.meansVerification']}: </Typography>
                {renderMultilineText(verificationMeans)}
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.informationSource']}:</Typography>
                {renderMultilineText(informationSource)}
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.dataCollection']}:</Typography>
                {renderMultilineText(dataCollectionMethodology)}
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'body2'}>{intlMessages['project.indicator.form.researchQuestions']}:</Typography>
                {renderMultilineText(researchQuestions)}
              </Grid>
              {openConfirmDeleteModal && (
                <ConfirmationModal
                  onConfirm={handleDeleteIndicator}
                  open={openConfirmDeleteModal}
                  onCancel={() => setConfirmDeleteModal(false)}
                  hideCloseAndFooter
                  tittle={<FormattedMessage id="project.indicator.message.delete.title" />}
                >
                  <Typography>{name}</Typography>
                </ConfirmationModal>
              )}
              {openEditModal && (
                <IndicatorModalForm
                  isOpen={openEditModal}
                  onClose={() => setOpenEditModal(false)}
                  defaultValues={{ ...detailIndicator }}
                  onSubmit={async values => {
                    await updateIndicator({ taskId: match.params.taskId, indicator: { ...values, id: match.params.idIndicator } });
                    setOpenEditModal(false);
                    refetch();
                  }}
                />
              )}
              {openRecordProgressModal && (
                <RegisterProgressIndicatorModal
                  isOpen={openRecordProgressModal}
                  onClose={() => setOpenRecordProgressModal(false)}
                  indicator={detailIndicator}
                  onSuccess={() => refetchIndicatorProgresses()}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
        <IndicatorDetailTable indicator={detailIndicator} indicatorProgresses={indicatorProgresses} />
      </BodyContent>
    </MuiThemeProvider>
  );
};

DetailIndicatorComponent.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export const DetailIndicator = connect()(withRouter(DetailIndicatorComponent));
