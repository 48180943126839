import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformCamel2Snake, transformSnake2Camel } from './utils';

export const useGetIndicatorVariables = (clientId, config = {}) => {
  const queryFn = async context => {
    const [clientId] = context.queryKey;
    const result = await getApi().get(`/clients/${clientId}/indicator_variable_types`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [clientId, 'GET_IndicatorVariables'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...config
  });
};

export const useCreateIndicatorVariable = (options = {}) =>
  useMutation(
    ({ clientId, indicator }) =>
      getApi()
        .post(`/clients/${clientId}/indicator_variable_types`, { indicator_variable_type: transformCamel2Snake(indicator) })
        .then(axiosResult => axiosResult.data),
    options
  );
