import React, { useEffect, useRef, useState } from 'react';
import { MuiThemeProvider, Card, CardContent, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { HeaderContent } from '../../components/molecules/HeaderContent';
import { BodyContent } from '../../components/molecules/BodyContent';
import { WideContainer } from '../../components/atoms/WideContainer';
import { FormulationTabs } from '../../components/organims/FormulationTabs';
import {
  useCreateFormulationLevel,
  useDeleteTaskLevel,
  useGetFormulationLevelsWithTasks,
  useUpdateAliasLevel
} from '../../queries/queryFormulation';
import { Loading } from '../../components/atoms/Loading';
import { FormulationTabPanel } from '../../components/organims/FormulationTabPanel';

export const FORMULATION_LEVEL_TYPES = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four'
};

const DEFAULT_SELECTED_TAB = { name: '...', parent: { name: '...', tasks: [] } };

const FormulationComponent = ({ match }) => {
  const { messages: intlMessages } = useIntl();
  const [initialLoading, setInitialLoading] = useState(false);
  const { data, isFetching, refetch } = useGetFormulationLevelsWithTasks(match.params.projectId, { refetchOnMount: true });
  const { mutate: createFormulationLevel, ...creationResponse } = useCreateFormulationLevel();
  const { mutate: updateAliasLevel } = useUpdateAliasLevel();
  const { mutate: deteleLevel } = useDeleteTaskLevel();

  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);
  const tabPosition = useRef(0);

  useEffect(() => {
    setInitialLoading(true);
  }, []);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (data && data.length > 0) {
      setInitialLoading(false);
      const normalizedTabs = data.map(x => ({ ...DEFAULT_SELECTED_TAB, ...x }));

      setTabs(normalizedTabs);
      setSelectedTab(normalizedTabs[normalizedTabs.length - 1]);
      return;
    }

    createFormulationLevel({
      projectId: match.params.projectId,
      params: {
        aliasId: '',
        levelType: FORMULATION_LEVEL_TYPES[1]
      }
    });
  }, [data]);

  useEffect(() => {
    if (creationResponse.isSuccess) {
      refetch();
    }
  }, [creationResponse.isSuccess]);

  const onChangeTab = currentTabs => {
    setTabs(currentTabs);
  };

  const addNewTab = () => {
    createFormulationLevel({
      projectId: match.params.projectId,
      params: {
        aliasId: '',
        levelType: FORMULATION_LEVEL_TYPES[tabs.length + 1]
      }
    });
  };

  const onSelectedTab = index => {
    if (tabs.length === 0) return;

    tabPosition.current = index;
    setSelectedTab(
      {
        ...tabs[index],
        parent: tabs[index - 1]
      } || DEFAULT_SELECTED_TAB
    );
  };

  const changeAlias = alias => {
    setTabs(prevTabs => {
      const updatedTabs = [...prevTabs];

      updateAliasLevel({ aliasId: alias.id, levelId: selectedTab.id, projectId: match.params.projectId });
      setSelectedTab({ ...selectedTab, levelAliasId: alias.id });
      updatedTabs[tabPosition.current].levelAliasId = alias.id;
      updatedTabs[tabPosition.current].name = alias.name;
      return updatedTabs;
    });
  };

  const onDeleteLevel = deletedTab => {
    deteleLevel({ levelId: deletedTab.id, projectId: match.params.projectId });
  };

  const onUpdateHandler = newChanges => {
    setSelectedTab(newChanges);
  };

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <HeaderContent
        title={intlMessages['projects.titleFormulation']}
        subtitle={intlMessages['projects.formulationDescription']}
      ></HeaderContent>
      <BodyContent component={WideContainer}>
        {initialLoading ? (
          <Grid container justifyContent="center">
            <Loading />
          </Grid>
        ) : (
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormulationTabs
                    tabs={tabs}
                    onChange={onChangeTab}
                    onAddTab={addNewTab}
                    onSelected={onSelectedTab}
                    onDelete={onDeleteLevel}
                  />
                </Grid>
                {selectedTab.id && <FormulationTabPanel level={selectedTab} onChangeAlias={changeAlias} onUpdate={onUpdateHandler} />}
              </Grid>
            </CardContent>
          </Card>
        )}
      </BodyContent>
    </MuiThemeProvider>
  );
};

FormulationComponent.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ projectId: PropTypes.string }) })
};

export const Formulation = withRouter(FormulationComponent);
