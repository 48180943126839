import React from 'react';
import { Modal } from '../molecules/Modal';
import PropTypes from 'prop-types';
import { Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { RegisterProgressIndicatorForm } from './RegisterProgressIndicatorForm';
import { palette } from '../../settings/themes/colors';
import { FormattedMessage } from 'react-intl';
import { useCreateIndicatorProgress } from '../../queries/queryIndicatorProgresses';

const styles = makeStyles({
  progressBar: {
    gap: lightThemeV2.spacing(2),
    '& *[class*=MuiLinearProgress-root]': {
      borderRadius: '4px',
      height: '8px'
    },
    '& *[class*="MuiLinearProgress-bar1Determinate"]': {
      backgroundColor: palette.captuDataYellow
    }
  }
});

export const RegisterProgressIndicatorModal = ({ isOpen, onClose, indicator, onSuccess }) => {
  const classes = styles();

  const { mutateAsync: createIndicatorProgress } = useCreateIndicatorProgress();
  const { name: nameIndicator, total_progress: totalProgress = 0, id: indicatorId, goal } = indicator || {};

  const onSubmit = async params => {
    const { effectiveDate, value, description, documents } = params;

    const mappedParams = {
      effective_date: effectiveDate,
      description,
      value,
      documents
    };

    await createIndicatorProgress({
      indicatorId,
      indicatorProgress: mappedParams
    });
    onSuccess();
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      tagtitle={<FormattedMessage id="project.indicator.details.register.advanceButton" />}
      title={nameIndicator}
      subtitle={
        <Grid container direction="row" alignItems="center" className={classes.progressBar}>
          <FormattedMessage id="mainContractStory.current_status" />
          <Grid item xs={8}>
            <LinearProgress variant="determinate" value={(totalProgress / goal) * 100} />
          </Grid>

          <p>
            {totalProgress} <FormattedMessage id="common.units" />
          </p>
        </Grid>
      }
    >
      <RegisterProgressIndicatorForm onCancel={onClose} onSubmit={onSubmit} />
    </Modal>
  );
};

RegisterProgressIndicatorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  indicator: PropTypes.shape({
    name: PropTypes.string,
    total_progress: PropTypes.number,
    id: PropTypes.number,
    goal: PropTypes.number
  }).isRequired,
  indicatorProgress: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired
};
