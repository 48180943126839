import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useHeaderContentStyles } from './HeaderContent.styles';

export const HeaderContent = ({ title, subtitle, children, breadcrumb, tagtitle, subtitleBar }) => {
  const classes = useHeaderContentStyles();

  return (
    <Box className={classes.root}>
      <Grid container justifyContent={'space-between'} alignItems="center">
        <Grid item>
          {breadcrumb && breadcrumb}
          {tagtitle && (
            <Typography variant={'h6'} className={classes.tagTitle}>
              {tagtitle}
            </Typography>
          )}
          <Typography variant={'h3'} className={classes.heading}>
            {title}
          </Typography>
          {subtitleBar && <Typography variant={'body1'}>{subtitleBar}</Typography>}
        </Grid>
        <Grid className={classes.controls}>{children}</Grid>
      </Grid>
      {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
    </Box>
  );
};

HeaderContent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  breadcrumb: PropTypes.node,
  tagtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitleBar: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
