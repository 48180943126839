import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AddOutlined } from '@material-ui/icons';
import { Variable } from './Variable';
import Button from '../../button';
import { lightThemeV2 } from '../../../settings/themes/lightThemeV2';
import { palette } from '../../../settings/themes/colors';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../../utils/auth';

const useStyles = makeStyles({
  addTabButton: {
    height: lightThemeV2.spacing(8),
    color: palette.cerulean,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  containterError: {
    marginLeft: '14px',
    marginRight: '14px'
  }
});

const MAX_SIZE = 5;

export const VariableIndicatorsComp = ({ clientId, onChange = () => {}, defaultValue = [undefined] }) => {
  const classes = useStyles();
  const [variablesToRender, setVariablesToRender] = useState(Array.from({ length: defaultValue.length }, (_, index) => index));
  const values = useRef(defaultValue);

  const handleChange = (index, value, variableIsValid) => {
    values.current[index] = variableIsValid ? value : undefined;
    const valuesIsValid = !values.current.some(x => x === undefined);

    onChange(values.current, valuesIsValid);
  };

  const handleAddVariable = () => {
    if (variablesToRender.length < MAX_SIZE) {
      setVariablesToRender([...variablesToRender, variablesToRender.length]);
      values.current.push(undefined);
    }
  };

  const handleRemoveVariable = indexToRemove => {
    setVariablesToRender(variablesToRender.filter((_, index) => index !== indexToRemove));
    values.current = values.current.filter((_, index) => index !== indexToRemove);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <Typography variant="subtitle2">
          <b>
            <FormattedMessage id="project.indicator.form.message.variables.helpertextTitle" />
          </b>
          <FormattedMessage id="project.indicator.form.message.variables.helpertext" />
        </Typography>
      </Grid>
      {variablesToRender.map((_, index) => (
        <Variable
          key={index}
          clientId={clientId}
          onChange={(value, variableIsValid) => handleChange(index, value, variableIsValid)}
          showCloseButton={index > 0}
          defaultValue={values.current[index]}
          onRemove={() => handleRemoveVariable(index)}
        />
      ))}
      {variablesToRender.length < MAX_SIZE && (
        <Button className={classes.addTabButton} startIcon={<AddOutlined />} onClick={handleAddVariable}>
          <FormattedMessage id="project.indicator.form.button.variables" />
        </Button>
      )}
    </Grid>
  );
};

VariableIndicatorsComp.propTypes = {
  onRemove: PropTypes.func,
  clientId: PropTypes.number,
  showCloseButton: PropTypes.bool,
  error: PropTypes.object,
  helperText: PropTypes.object,
  schema: PropTypes.object
};

const mapStateToProps = state => ({
  clientId: getCurrentUser(state).client_id
});

export const VariableIndicators = connect(mapStateToProps)(VariableIndicatorsComp);
