import { Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { WrapRequiredLabel } from '../../atoms/WrapRequiredLabel';
import { palette } from '../../../settings/themes/colors';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutoCompleteSelectorOptions } from '../AutoCompleteSelectorOptions';
import { filterOptions } from '../../../containers/Projects/components/project/Budget/utils';
import { useCreateIndicatorVariable, useGetIndicatorVariables } from '../../../queries/queryIndicatorVariables';

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },
  containterError: {
    marginLeft: '14px',
    marginRight: '14px'
  }
});

export const Variable = ({
  onRemove,
  showCloseButton,
  onChange = () => {},
  clientId,
  defaultValue = { description: '', indicatorVariableTypeId: null }
}) => {
  const classes = useStyles();
  const { messages: intlMessages } = useIntl();
  const values = useRef(defaultValue);
  const [errors, setErrors] = useState({});
  const { data: indicatorVariableTypes = [], refetch, isRefetching, isLoading: isIndicatorsLoading } = useGetIndicatorVariables(clientId, {
    enable: false
  });
  const { mutateAsync: createIndicatorVariable } = useCreateIndicatorVariable();
  const [selectedIndicatorVariable, setSelectedIndicatorVariable] = useState();

  useEffect(() => {
    refetch();
  }, []);

  const createNewIndicatorVariable = async name => {
    try {
      const newIndicatorVariable = await createIndicatorVariable({ clientId, indicator: { name } });

      refetch();

      return newIndicatorVariable;
    } catch (error) {
      console.error('error', error);
    }
  };

  const validation = () => {
    const currentErrors = {};
    let isValid = true;

    if (!values.current.indicatorVariableTypeId) {
      currentErrors.indicatorVariableTypeId = intlMessages['beneficiaries.form.required'];
      isValid = false;
    }
    if (!values.current.description || values.current.description.length === 0) {
      currentErrors.description = intlMessages['beneficiaries.form.required'];
      isValid = false;
    }
    setErrors(isValid ? {} : currentErrors);
    return isValid;
  };

  useEffect(() => {
    validation();
  }, []);

  const validateAndEmit = (field, value) => {
    values.current[field] = value;
    const isValid = validation();

    onChange(values.current, isValid);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <AutoCompleteSelectorOptions
          value={selectedIndicatorVariable}
          defaultValue={values.current.indicatorVariableTypeId}
          data={indicatorVariableTypes}
          createOption={createNewIndicatorVariable}
          isLoading={isIndicatorsLoading || isRefetching}
          onChange={value => {
            const newValue = value && value.id ? value.id : value;

            setSelectedIndicatorVariable(newValue);
            validateAndEmit('indicatorVariableTypeId', newValue);
          }}
          error={!!errors.indicatorVariableTypeId}
          helperText={errors.indicatorVariableTypeId}
          filterOptions={(x, y) => filterOptions(x, y, intlMessages['project.indicator.form.button.variables'])}
          label={<WrapRequiredLabel label={<FormattedMessage id={'common.variables'} />} />}
        />
      </Grid>
      {showCloseButton && (
        <Grid className={classes.container} style={{ alignItems: !!errors.indicatorVariableTypeId ? 'center' : 'flex-end' }} item xs={1}>
          <IconButton style={{ color: palette.cerulean }} onClick={onRemove}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={11}>
        <TextField
          multiline
          minRows={4}
          defaultValue={values.current.description}
          onChange={e => validateAndEmit('description', e.target.value)}
          onBlur={() => validateAndEmit('description', values.current.description)}
          error={!!errors.description}
          helperText={errors.description}
          label={<WrapRequiredLabel label={<FormattedMessage id={'project.documents.description'} />} />}
        />
      </Grid>
    </Grid>
  );
};

Variable.propTypes = {
  onRemove: PropTypes.func,
  clientId: PropTypes.number,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  showCloseButton: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.object
};
