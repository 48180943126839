import { useCallback } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformCamel2Snake, transformSnake2Camel } from './utils';

export const useGetFormulationLevels = projectId => {
  const queryFn = async context => {
    const [id] = context.queryKey;
    const result = await getApi().get(`projects/${id}/task_levels/`);

    return result.data;
  };

  return useQuery({
    queryKey: [projectId, 'GET_formulation'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
export const useGetFormulationLevelsWithTasks = (projectId, config = {}) => {
  const queryFn = async context => {
    const [id] = context.queryKey;
    const { onSuccess } = config;

    if (!id) return [];

    const result = await getApi().get(`projects/${id}/task_levels/`);

    const tasksResponse = await Promise.all([...result.data.map(x => getApi().get(`project_task_levels/${x.id}/tasks`))]);

    const formattedData = tasksResponse.map((response, index) => ({
      ...transformSnake2Camel(result.data[index]),
      tasks: transformSnake2Camel(response.data)
    }));

    if (onSuccess) onSuccess(formattedData);

    return formattedData;
  };

  return useQuery({
    queryKey: [projectId, 'GET_formulation'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...config
  });
};

export const useCreateFormulationLevel = () =>
  useMutation(async ({ projectId, params }) =>
    getApi().post(`projects/${projectId}/task_levels`, { project_level: transformCamel2Snake(params) })
  );

export const useUpdateAliasLevel = () =>
  useMutation(async ({ projectId, levelId, aliasId }) =>
    getApi().put(`projects/${projectId}/task_levels/${levelId}`, { project_level: { level_alias_id: aliasId } })
  );

export const useCreateTaskLevel = () => {
  const { data: response, ...mutation } = useMutation(async ({ levelId, task }) =>
    getApi().post(`project_task_levels/${levelId}/tasks`, transformCamel2Snake(task))
  );

  return { data: response ? transformSnake2Camel(response.data) : undefined, ...mutation };
};

export const useUpdateTaskLevel = () => {
  const { data: response, ...mutation } = useMutation(async ({ levelId, task, taskId }) =>
    getApi().put(`project_task_levels/${levelId}/tasks/${taskId}`, transformCamel2Snake(task))
  );

  return { data: response ? transformSnake2Camel(response.data) : undefined, ...mutation };
};

export const useDeleteTaskLevel = () =>
  useMutation(async ({ levelId, projectId }) => getApi().delete(`projects/${projectId}/task_levels/${levelId}`));

export const useGetTasksLevel = levelId => {
  const queryFn = useCallback(
    async context => {
      const [id] = context.queryKey;
      const result = await getApi().get(`project_task_levels/${id}/tasks`);

      return result.data.map(level => transformSnake2Camel(level));
    },
    [levelId]
  );

  return useQuery({
    queryKey: [levelId, 'GET_tasks_level'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
