import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { Main } from './containers/Main';
import InspectionRoutes from './containers/Inspections/routes';
import AnalyticRoutes from './containers/Analytic/routes';
import FormularyReportsRoutes from './containers/FormularyReports/routes';
import OperativeRoutes from './containers/Operative/routes';
import AdminRoutes from './containers/Admin/routes';
import WorkOrdersRoutes from './containers/WorkOrders/routes';
import ProjectsRoutes from './containers/Projects/routes';
import ReportsRoutes from './containers/Reports/routes';
import asyncComponent from './utils/AsyncFunc';
import { checkAuthState, getCurrentUser } from './utils/auth';
import Header from './components/header';
import Footer from './components/footer';
import ToastMessage from './components/toastMessage';
import { CLIENT_IDS_ALLOWED_FOR_CONTACTS } from './utils/constants';
import Programs from './containers/Programs';
import ResetPassword from './containers/Auth/login/resetPassword';
import { Contacts } from './containers/Beneficiaries/Beneficiaries';
import { BeneficiaryDetail } from './containers/Beneficiaries/BeneficiaryDetail';
import { CreateBeneficiary } from './containers/Beneficiaries/CreateBeneficiary';
import { EditBeneficiary } from './containers/Beneficiaries/EditBeneficiary';
import { Formulation } from './containers/Projects/Formulation';
import { CreateGroup } from './containers/Beneficiaries/CreateGroup';
import { GroupDetail } from './containers/Beneficiaries/GroupDetail';
import { EditContactGroup } from './containers/Beneficiaries/EditContactGroup';
import { Invoices } from './containers/Projects/Invoices';
import { DetailIndicator } from './components/organims/DetailIndicator';

const Dashboard = ({ match, children }) => {
  const { path } = match;

  return (
    <>
      <Header currentPath={path} />
      {children}
      <Footer />
      <ToastMessage />
    </>
  );
};

Dashboard.propTypes = {
  match: PropTypes.object,
  children: PropTypes.node
};

const RestrictedRoute = ({ component: Component = Main, isLoggedIn = false, router, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { pathname, search } = props.location || {};
      const queryFrom = pathname && `?from=${pathname}${search}`;

      return isLoggedIn ? (
        <Dashboard {...props}>
          <Component router={router} {...props} />
        </Dashboard>
      ) : (
        <Redirect to={`/login?from=${queryFrom}`} />
      );
    }}
  />
);

RestrictedRoute.propTypes = {
  component: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  router: PropTypes.func
};

const InitRoutes = ({ history, isLoggedIn, currentUser }) => {
  const isAdmin = !!isLoggedIn && !['visualizator', 'inspector'].find(t => t === currentUser.user_type);
  const { client_id: clientId } = currentUser || {};
  const isNotViewer = !!isLoggedIn && currentUser.user_type !== 'visualizator';

  const isActionHelp = Component => (CLIENT_IDS_ALLOWED_FOR_CONTACTS.includes(clientId) ? Component : () => <Redirect to={`/projects`} />);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={'/'} component={() => <Redirect to={'/login'} />} />
        <Route exact path={'/login'} component={asyncComponent(() => import('./containers/Auth/login'))} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route
          exact
          path={'/projects-info/:sharedToken'}
          component={asyncComponent(() => import('./containers/ProjectsInfo/OpenProject'))}
        />
        <Route exact path={'/projects-info'} component={asyncComponent(() => import('./containers/ProjectsInfo/index'))} />
        <RestrictedRoute path={'/operative/projects/:projectId'} router={InspectionRoutes} isLoggedIn={isLoggedIn} />
        <RestrictedRoute
          path={'/projects/:projectId/task/:id/budget_lines/:budgetLineId/invoices'}
          component={isActionHelp(Invoices)}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path={'/projects/:projectId/formulation/:taskId/indicators/:idIndicator'}
          component={isActionHelp(DetailIndicator)}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute path={'/projects/:projectId/formulation'} component={isActionHelp(Formulation)} isLoggedIn={isLoggedIn} />
        <RestrictedRoute path={'/projects/:projectId'} router={ProjectsRoutes} isLoggedIn={isLoggedIn} />
        <RestrictedRoute path={'/projects'} router={ProjectsRoutes} isLoggedIn={isLoggedIn} />
        <RestrictedRoute path={'/formulary-reports'} router={FormularyReportsRoutes} isLoggedIn={isAdmin} />
        <RestrictedRoute path={'/operative'} router={OperativeRoutes} isLoggedIn={isLoggedIn} />
        <RestrictedRoute path={'/executive'} component={() => <Programs clientId={clientId} />} isLoggedIn={isLoggedIn} />
        <RestrictedRoute path={'/analytic'} router={AnalyticRoutes} isLoggedIn={isLoggedIn} />
        <RestrictedRoute path={'/admin'} router={AdminRoutes} isLoggedIn={isAdmin} />
        <RestrictedRoute path={'/work-orders'} router={WorkOrdersRoutes} isLoggedIn={isNotViewer} />
        <RestrictedRoute path={'/reports'} router={ReportsRoutes} isLoggedIn={isNotViewer} />
        <RestrictedRoute path={'/beneficiaries/create'} component={isActionHelp(withRouter(CreateBeneficiary))} isLoggedIn={isLoggedIn} />
        <RestrictedRoute path={'/groups/create'} component={isActionHelp(withRouter(CreateGroup))} isLoggedIn={isLoggedIn} />
        <RestrictedRoute
          path={'/beneficiaries/:beneficiaryId/edit'}
          component={isActionHelp(withRouter(EditBeneficiary))}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute path={'/groups/:groupId/edit'} component={isActionHelp(withRouter(EditContactGroup))} isLoggedIn={isLoggedIn} />
        <RestrictedRoute
          path={'/beneficiaries/:beneficiaryId'}
          component={isActionHelp(withRouter(BeneficiaryDetail))}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute path={'/beneficiaries'} component={isActionHelp(Contacts)} isLoggedIn={isLoggedIn} />
        <RestrictedRoute path={'/groups/:groupId'} component={isActionHelp(withRouter(GroupDetail))} isLoggedIn={isLoggedIn} />
        <Route exact path={'/tests'} component={asyncComponent(() => import('./containers/Tests'))} />
      </Switch>
    </ConnectedRouter>
  );
};

InitRoutes.propTypes = {
  history: PropTypes.object,
  isLoggedIn: PropTypes.bool
};

export default connect(state => ({
  currentUser: getCurrentUser(state),
  isLoggedIn: checkAuthState(state)
}))(InitRoutes);
