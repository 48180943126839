import React, { useState } from 'react';
import { Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { string, object, array } from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WrapRequiredLabel } from '../../../../../../components/atoms/WrapRequiredLabel.jsx';
import CurrencyInput from '../../../currencyField/index.jsx';
import { Modal } from '../../../../../../components/molecules/Modal.jsx';
import { MappingFormFields } from '../../../../../../components/organims/MappingFormFields.jsx';
import { getCurrentUser } from '../../../../../../utils/auth.js';
import { lightThemeV2 } from '../../../../../../settings/themes/lightThemeV2.js';
import { NativeDatePicker } from '../../../../../../components/molecules/NativeDatePicker.jsx';
import { DragAndDropField } from '../DragAndDropField.jsx';
import { useCreateSupplier, useGetSuppliers } from '../../../../../../queries/querySuppliers.js';
import { AutoCompleteSelectorOptions } from '../../../../../../components/organims/AutoCompleteSelectorOptions.jsx';
import { filterOptions } from '../utils.js';
import { useCreateInvoice, useUpdateInvoice } from '../../../../../../queries/queryInvoices.js';
import { ErrorMessage } from '../../../../../../components/organims/ErrorMessage.jsx';
import { ExecutorFieldInvoice } from '../ExecutorFieldInvoice.jsx';
import { removeDecimalsFromAmount } from '../../utils.js';
import { useStyles as useBudgetStyles } from '../useStyles.js';
import { isArray, isNumber } from 'lodash';
import { useGetImplementingUnits } from '../../../../../../queries/queryImplementingUnits.js';
import { AmountFieldByBudgetLine } from './AmountFieldByBudgetLine.jsx';
import { useNotificationContext } from '../../../../../../components/organims/Notification/NotificationContext.jsx';
import { replaceStrings, SIMPLE_DASH_DATE_FORMAT } from '../../../../../../utils/utils.js';

const useStyles = makeStyles({
  error: {
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    fontWeight: 400
  },
  buttonControls: {
    marginTop: lightThemeV2.spacing(2)
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

const INVOICE_TYPE = Object.freeze({
  INVOICE: 'invoice',
  COUNTERPART: 'counterparty'
});

const calculateAvailableAmount = (budgetLine, implementingUnitId, currentAmount) => {
  const { taskBudgetLineImplementingUnitDetails = [] } = budgetLine;
  const selectedTaskBudgetLineImplementingUnitDetails =
    taskBudgetLineImplementingUnitDetails.find(item => item.contact.id === implementingUnitId) || {};
  const { amount = 0, executedAmount = 0 } = selectedTaskBudgetLineImplementingUnitDetails;

  return amount - executedAmount + (currentAmount || 0);
};

const InvoiceModalFormComponent = ({
  budgetLines = [],
  isOpen,
  onClose,
  defaultValues = {},
  contractId,
  currency,
  projectId,
  clientId
}) => {
  const classes = useStyles();
  const budgetClasses = useBudgetStyles();
  const { messages: intlMessages } = useIntl();
  const { showSuccessNotification } = useNotificationContext();
  const [budgetAmountAvailable, setBudgetAmountAvailable] = useState(
    defaultValues.implementingUnitId
      ? calculateAvailableAmount(budgetLines[0], defaultValues.implementingUnitId, defaultValues.total)
      : undefined
  );
  const [globalErrors, setGlobalErrors] = useState({});
  const isMultipleTasks = budgetLines.length > 1;

  const { data: suppliers = [], refetch, isRefetching, isLoading: isSuppliersLoading } = useGetSuppliers(clientId);
  const { mutateAsync: createSupplier } = useCreateSupplier();
  const { data: executors = [] } = useGetImplementingUnits(projectId);

  const initialValues = {
    date: defaultValues.date || moment().format(SIMPLE_DASH_DATE_FORMAT),
    consecutive: defaultValues.consecutive || '',
    type: defaultValues.type || INVOICE_TYPE.INVOICE,
    amount: defaultValues.total || '',
    supplier: defaultValues.supplierId || '',
    concept: defaultValues.concept || '',
    documentsAttributes: defaultValues.files || [],
    implementingUnitId: defaultValues.implementingUnitId || ''
  };

  const validationSchema = object().shape({
    date: string().required(intlMessages['common.field.required']),
    consecutive: string().required(intlMessages['common.field.required']),
    supplier: string().required(intlMessages['common.field.required']),
    concept: string().required(intlMessages['common.field.required']),
    implementingUnitId: string().required(intlMessages['common.field.required']),
    amount: string()
      .required(intlMessages['common.field.required'])
      .test('must-be-greater-that-zero', intlMessages['common.field.greaterThatZero'], value => {
        const amount = removeDecimalsFromAmount(value);
        return amount > 0;
      })
      .test('budget-no-available', intlMessages['project.budget.amount.no.available'], value => {
        if (isMultipleTasks) return true;
        if (!isNumber(budgetAmountAvailable) || budgetAmountAvailable <= 0) return false;

        const amount = removeDecimalsFromAmount(value);
        return isNumber(budgetAmountAvailable) && budgetAmountAvailable >= amount;
      }),
    type: string().required(intlMessages['common.field.required']),
    taskBudgetLineImplementingUnitDetails: array()
      .when('amount', (_, schema) =>
        isMultipleTasks ? schema.min(1, intlMessages['project.budget.invoice.register.form.atLeastOneImplementingUnit']) : schema
      )
      .of(
        string().test('amount-no-available-by-implementing-unit', intlMessages['project.budget.amount.no.available'], (value, context) => {
          if (!isMultipleTasks) return true;
          const budgetLineMatched = budgetLines.find((_, index) => `taskBudgetLineImplementingUnitDetails[${index}]` === context.path);
          const implementingUnitAmount = removeDecimalsFromAmount(value);
          const availableAmount = budgetLineMatched.budgetAmount - budgetLineMatched.executedAmount;

          return !implementingUnitAmount || implementingUnitAmount <= availableAmount;
        })
      ),
    documentsAttributes: array().test(
      'documentsAttributes-no-empty',
      intlMessages['project.budget.invoice.register.form.filedRequired'],
      value => {
        if (defaultValues.id) {
          return true;
        }

        return value.length > 0;
      }
    )
  });

  const { mutateAsync: createInvoice } = useCreateInvoice({
    onSuccess: response => {
      showSuccessNotification(
        intlMessages['project.budget.invoice.notification.success'],
        replaceStrings(intlMessages['project.budget.invoice.message.success'], [response.number, `${budgetLines[0].name}`])
      );
      onClose();
    }
  });

  const { mutateAsync: updateInvoice } = useUpdateInvoice({
    onSuccess: response => {
      showSuccessNotification(
        intlMessages['project.budget.invoice.notification.success'],
        replaceStrings(intlMessages['project.budget.invoice.message.update.success'], [response.number])
      );
      onClose();
    }
  });

  const globalValidate = ({ amount, taskBudgetLineImplementingUnitDetails = [] }) => {
    const amountAsNumber = removeDecimalsFromAmount(amount === '' ? '0' : amount);
    let errors = {};

    const totalAmountByImplementingUnit = taskBudgetLineImplementingUnitDetails
      .filter(x => x !== '')
      .map(x => removeDecimalsFromAmount(x))
      .reduce((acc, item) => acc + item, 0);

    if (totalAmountByImplementingUnit > amountAsNumber) {
      errors.taskBudgetLineImplementingUnitDetails = intlMessages['project.budget.invoice.register.form.exceedsTotalAmount'];
    } else if (totalAmountByImplementingUnit < amountAsNumber) {
      errors.taskBudgetLineImplementingUnitDetails = intlMessages['project.budget.invoice.register.form.lessThanTotalAmount'];
    }

    setGlobalErrors({
      ...errors
    });
  };

  const { getFieldProps, setFieldValue, setValues, handleSubmit, values, errors, isValid } = useFormik({
    validationSchema,
    initialValues,
    validate: values => (isMultipleTasks ? globalValidate(values) : {}),
    onSubmit: toSubmitValues => {
      const {
        consecutive,
        date,
        concept,
        type,
        supplier: supplierId,
        amount,
        implementingUnitId,
        documentsAttributes,
        taskBudgetLineImplementingUnitDetails
      } = toSubmitValues;

      const implementingUnitDetails = isMultipleTasks
        ? taskBudgetLineImplementingUnitDetails.map((implementingAmount, index) => ({
            amount: removeDecimalsFromAmount(implementingAmount),
            taskBudgetLineId: budgetLines[index].id,
            implementingUnitId
          }))
        : [
            {
              amount: removeDecimalsFromAmount(amount),
              taskBudgetLineId: budgetLines[0].id,
              implementingUnitId
            }
          ];

      const data = {
        number: consecutive,
        effectiveDate: moment(date).format(SIMPLE_DASH_DATE_FORMAT),
        concept,
        invoiceType: type,
        supplierId,
        implementingUnitDetails,
        documentsAttributes
      };

      if (defaultValues.id) {
        updateInvoice({ contractId, invoice: data, id: defaultValues.id });
      } else {
        createInvoice({ contractId, invoice: data });
      }
    }
  });

  const handleChangeData = (value = {}) => {
    setFieldValue('supplier', value && value.id ? value.id : value);
  };

  const createNewSupplier = async firstName => {
    try {
      const newSupplier = await createSupplier({ clientId, contact: { firstName } });
      setFieldValue('supplier', newSupplier.id);
      refetch();

      return newSupplier;
    } catch (error) {
      console.error('error', error);
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  });

  const amountsByImplementingUnit = isMultipleTasks
    ? [
        {
          style: { marginTop: lightThemeV2.spacing(2) },
          component: Divider,
          xs: 12
        },
        ...budgetLines.map((budgetLine, index) => {
          return {
            budgetLine,
            implementingUnitId: values.implementingUnitId,
            error: isArray(errors.taskBudgetLineImplementingUnitDetails) ? errors.taskBudgetLineImplementingUnitDetails[index] : undefined,
            name: `taskBudgetLineImplementingUnitDetails[${index}]`,
            component: AmountFieldByBudgetLine,
            currency,
            totalValue: removeDecimalsFromAmount(values.amount || 0),
            xs: 12
          };
        }),
        {
          component: ErrorMessage,
          error: globalErrors.taskBudgetLineImplementingUnitDetails,
          children: globalErrors.taskBudgetLineImplementingUnitDetails,
          xs: 12
        }
      ]
    : [];

  const executorOptions = budgetLines.map(({ taskBudgetLineImplementingUnitDetails }) =>
    executors.map(executor => {
      const amount = (taskBudgetLineImplementingUnitDetails.find(x => x.contact.id === executor.id) || {}).amount || 0;
      return { ...executor, amount };
    })
  );

  const formFields = [
    {
      name: 'type',
      component: ({ xs: _, label, ...props }) => (
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            <FormattedMessage id="project.budget.invoice.register.form.label.type" />
          </Grid>
          <Grid item>
            <RadioGroup row {...props}>
              <FormControlLabel value={INVOICE_TYPE.INVOICE} control={<Radio />} label="Factura" />
              <FormControlLabel value={INVOICE_TYPE.COUNTERPART} control={<Radio />} label="Contrapartida" />
            </RadioGroup>
          </Grid>
        </Grid>
      ),
      xs: 12
    },
    {
      label: <WrapRequiredLabel label={intlMessages['project.budget.invoice.register.form.label.date']} />,
      name: 'date',
      onChange: date => {
        setFieldValue('date', date.format(SIMPLE_DASH_DATE_FORMAT));
      },
      InputProps: { inputProps: { max: moment().format(SIMPLE_DASH_DATE_FORMAT) } },
      component: NativeDatePicker,
      xs: 12
    },
    {
      label: <WrapRequiredLabel label={<FormattedMessage id="project.budget.invoice.register.form.label.consecutive" />} />,
      name: 'consecutive',
      xs: 12
    },
    {
      label: <WrapRequiredLabel label={<FormattedMessage id="project.budget.invoice.register.form.label.supplier" />} />,
      component: AutoCompleteSelectorOptions,
      data: suppliers,
      name: 'supplier',
      required: true,
      onChange: handleChangeData,
      createOption: createNewSupplier,
      isLoading: isSuppliersLoading || isRefetching,
      filterOptions: (x, y) => filterOptions(x, y, intlMessages['project.budget.addNewSupplier']),
      xs: 12
    },
    {
      label: <WrapRequiredLabel label={<FormattedMessage id="common.concept" />} />,
      name: 'concept',
      multiline: true,
      error: errors.concept,
      minRows: 4,
      xs: 12
    },
    {
      component: ExecutorFieldInvoice,
      onChange: event => {
        const id = event.target.value;

        if (!isMultipleTasks) {
          setBudgetAmountAvailable(calculateAvailableAmount(budgetLines[0], id, defaultValues.total));
        }

        setFieldValue('implementingUnitId', id);
      },
      currency,
      name: 'implementingUnitId',
      implementingUnits: executorOptions[0],
      xs: 12
    },
    {
      component: () =>
        isNumber(budgetAmountAvailable) && budgetAmountAvailable > 0 ? (
          <Typography className={budgetClasses.tipNote} style={{ paddingLeft: '5px' }}>
            {`${intlMessages['project.budget.card.balance']}: ${formatter.format(budgetAmountAvailable)}`}
          </Typography>
        ) : (
          <ErrorMessage error={isNumber(budgetAmountAvailable)} id="project.budget.no.balance" />
        ),
      xs: 12
    },
    {
      label: <FormattedMessage id="project.budget.invoice.register.form.label.amount" />,
      name: 'amount',
      component: CurrencyInput,
      suffix: currency,
      xs: 12
    },
    ...amountsByImplementingUnit,
    {
      label: <WrapRequiredLabel label={<FormattedMessage id="project.budget.invoice.register.form.label.addFile" />} />,
      name: 'documentsAttributes',
      onChange: documentsAttributes => {
        setFieldValue('documentsAttributes', documentsAttributes);
      },
      component: ({ label, ...props }) => (
        <Grid container direction="column" spacing={1}>
          <Grid item> {label}</Grid>
          <Grid item>
            <DragAndDropField {...props} />
          </Grid>
        </Grid>
      ),
      xs: 12
    },
    {
      component: ErrorMessage,
      error: errors.documentsAttributes,
      id: 'project.budget.invoice.register.form.filedRequired',
      xs: 12
    }
  ];

  const onClosebehavior = () => {
    onClose();
    setValues(initialValues);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClosebehavior}
      title={<FormattedMessage id="project.budget.invoice.register.title" />}
      subtitle={<WrapRequiredLabel label={<FormattedMessage id="common.mandatoryFields" />} />}
    >
      <Grid container spacing={2}>
        {!isMultipleTasks && (
          <Grid item container direction="column">
            {budgetLines[0] && budgetLines[0].task && (
              <Typography className={classes.ellipsis}>{budgetLines[0].task.description}</Typography>
            )}
            <Typography>{budgetLines[0].name}</Typography>
          </Grid>
        )}
        <form>
          <MappingFormFields fields={formFields} getFieldProps={getFieldProps} errors={errors} />
          <Grid container spacing={2} className={classes.buttonControls}>
            <Grid item>
              <Button
                variant="contained"
                type="button"
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <FormattedMessage id="project.budget.invoice.register.form.submit" />
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => onClosebehavior()} type="button">
                <FormattedMessage id="common.cancel" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Modal>
  );
};

InvoiceModalFormComponent.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  clientId: PropTypes.number,
  defaultValues: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string,
    consecutive: PropTypes.number,
    type: PropTypes.string,
    total: PropTypes.string,
    concept: PropTypes.string,
    supplierId: PropTypes.number,
    implementingUnitId: PropTypes.number
  }),
  budgetLines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  contractId: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({ clientId: getCurrentUser(state).client_id });

export const InvoiceModalForm = connect(mapStateToProps)(InvoiceModalFormComponent);
