import React, { useRef, useState } from 'react';
import { Modal } from '../molecules/Modal';
import { WrapRequiredLabel } from '../atoms/WrapRequiredLabel';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Divider, Grid, makeStyles, MenuItem } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { SelectField } from '../molecules/SelectField';
import Button from '../button';
import { VariableIndicators } from './VariableIndicators';
import { palette } from '../../settings/themes/colors';
import { array, number, object, string } from 'yup';
import { TextFormField } from '../atoms/form/TextFormField';

const styles = makeStyles({
  addTabButton: {
    height: lightThemeV2.spacing(8),
    color: palette.cerulean,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  buttonContainer: {
    gap: lightThemeV2.spacing(2),
    paddingTop: lightThemeV2.spacing(2)
  }
});

const MEASUREMENT_FRECUENCY = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Biannually', value: 'biannually' },
  { label: 'Annually', value: 'annually' }
];

const schema = object().shape({
  name: string().required(<FormattedMessage key={'name'} id="common.field.required" />),
  baseLine: number()
    .typeError(<FormattedMessage key={'baseLine'} id="common.error.message.number" />)
    .required(<FormattedMessage key={'baseLine'} id="common.field.required" />),
  goal: number().typeError(<FormattedMessage key={'goal'} id="common.error.message.number" />),
  indicatorVariables: array()
    .of(object())
    .min(1, <FormattedMessage id="common.field.required" />),
  measurementUnit: string().required(<FormattedMessage key={'measurementUnit'} id="common.field.required" />),
  calculationEquation: string().required(<FormattedMessage key={'calculationEquation'} id="common.field.required" />),
  measurementFrequency: string().required(<FormattedMessage key={'measurementFrequency'} id="common.field.required" />),
  verificationMeans: string().required(<FormattedMessage key={'verificationMeans'} id="common.field.required" />),
  informationSource: string().required(<FormattedMessage key={'informationSource'} id="common.field.required" />)
});

export const IndicatorModalForm = ({ isOpen, onClose = () => {}, defaultValues = {}, onSubmit }) => {
  const classes = styles();
  const initialValues = {
    name: defaultValues.name || '',
    baseLine: defaultValues.baseLine || '',
    indicatorVariables: defaultValues.indicatorVariables || [],
    measurementUnit: defaultValues.measurementUnit || '',
    goal: defaultValues.goal || '',
    calculationEquation: defaultValues.calculationEquation || '',
    measurementFrequency: defaultValues.measurementFrequency || '',
    verificationMeans: defaultValues.verificationMeans || '',
    informationSource: defaultValues.informationSource || '',
    researchQuestions: defaultValues.researchQuestions || '',
    dataCollectionMethodology: defaultValues.dataCollectionMethodology || ''
  };

  const currentValues = useRef(initialValues);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValidation, setInitialValidation] = useState(false);

  const handlerChange = (fieldName, value) => {
    currentValues.current[fieldName] = value;
  };

  const validateForm = async () => {
    try {
      await schema.validate(currentValues.current, { abortEarly: false });
      setErrorMessage('');
      return true;
    } catch (errors) {
      setErrorMessage(<FormattedMessage id="common.error.message.fields" />);
      return false;
    }
  };

  const onSubmitHandler = async () => {
    setInitialValidation(true);
    const isValidForm = await validateForm();

    if (isValidForm) {
      onSubmit(currentValues.current);
      currentValues.current = { ...initialValues };
      setInitialValidation(false);
      setErrorMessage('');
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={<FormattedMessage id="project.indicator.addIndicator" />}
      subtitle={<WrapRequiredLabel label={<FormattedMessage id="common.mandatoryFields" />} />}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextFormField
            schema={schema.fields.name}
            initialValidation={initialValidation}
            label={<WrapRequiredLabel label={<FormattedMessage id="settings.formularies.column.name" />} />}
            onChange={value => handlerChange('name', value)}
            defaultValue={initialValues.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <VariableIndicators
            onChange={value => {
              handlerChange('indicatorVariables', value);
            }}
            defaultValue={initialValues.indicatorVariables}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            schema={schema.fields.baseLine}
            initialValidation={initialValidation}
            label={<WrapRequiredLabel label={<FormattedMessage id="project.indicator.form.lineBase" />} />}
            onChange={value => handlerChange('baseLine', value)}
            defaultValue={initialValues.baseLine}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            schema={schema.fields.measurementUnit}
            initialValidation={initialValidation}
            label={<WrapRequiredLabel label={<FormattedMessage id="project.indicator.form.unitMeasurement" />} />}
            onChange={value => handlerChange('measurementUnit', value)}
            defaultValue={initialValues.measurementUnit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            schema={schema.fields.goal}
            label={<FormattedMessage id="project.formulation.form.goal" />}
            onChange={value => handlerChange('goal', value)}
            defaultValue={initialValues.goal}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            schema={schema.fields.calculationEquation}
            initialValidation={initialValidation}
            label={<WrapRequiredLabel label={<FormattedMessage id="project.indicator.form.calulationForm" />} />}
            onChange={value => handlerChange('calculationEquation', value)}
            defaultValue={initialValues.calculationEquation}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            defaultValue={initialValues.measurementFrequency}
            label={<WrapRequiredLabel label={<FormattedMessage id="project.indicator.form.frequencyMeasure" />} />}
            onChange={e => handlerChange('measurementFrequency', e.target.value)}
          >
            {MEASUREMENT_FRECUENCY.map(option => (
              <MenuItem key={option.value} value={option.value}>
                <FormattedMessage id={`project.indicator.details.frequencyMeasure.${option.value}`} />
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            schema={schema.fields.verificationMeans}
            initialValidation={initialValidation}
            label={<WrapRequiredLabel label={<FormattedMessage id="project.indicator.form.meansVerification" />} />}
            onChange={value => handlerChange('verificationMeans', value)}
            defaultValue={initialValues.verificationMeans}
            multiline={true}
            minRows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            schema={schema.fields.informationSource}
            initialValidation={initialValidation}
            defaultValue={initialValues.informationSource}
            label={<WrapRequiredLabel label={<FormattedMessage id="project.indicator.form.informationSource" />} />}
            onChange={value => handlerChange('informationSource', value)}
            multiline={true}
            minRows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            label={<FormattedMessage id="project.indicator.form.researchQuestions" />}
            onChange={value => handlerChange('researchQuestions', value)}
            defaultValue={initialValues.researchQuestions}
            multiline={true}
            minRows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            label={<FormattedMessage id="project.indicator.form.dataCollection" />}
            onChange={value => handlerChange('dataCollectionMethodology', value)}
            defaultValue={initialValues.dataCollectionMethodology}
            multiline={true}
            minRows={4}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.buttonContainer}>
        {errorMessage && (
          <Grid item xs={12}>
            <p style={{ color: '#f44336' }}>{errorMessage}</p>
          </Grid>
        )}

        <Grid item>
          <Button variant="contained" onClick={onSubmitHandler}>
            <FormattedMessage id="project.indicator.form.button.saveIndicator" />
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={onClose}>
            <FormattedMessage id="common.cancel" />
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

IndicatorModalForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func
};
