import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformCamel2Snake, transformSnake2Camel } from './utils';

export const useGetIndicators = (taskId, config = {}) => {
  const queryFn = async context => {
    const [taskId] = context.queryKey;
    const result = await getApi().get(`/tasks/${taskId}/indicators`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [taskId, 'GET_Indicators'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...config
  });
};

export const useCreateIndicator = (options = {}) =>
  useMutation(
    ({ taskId, indicator }) =>
      getApi()
        .post(`/tasks/${taskId}/indicators`, { indicator: transformCamel2Snake(indicator) })
        .then(axiosResult => axiosResult.data),
    options
  );

export const useUpdateIndicator = (options = {}) =>
  useMutation(
    ({ taskId, indicator }) =>
      getApi()
        .put(`/tasks/${taskId}/indicators/${indicator.id}`, { indicator: transformCamel2Snake(indicator) })
        .then(axiosResult => axiosResult.data),
    options
  );

export const useGetDetailIndicatorVariables = (taskId, id, config = {}) => {
  const queryFn = async context => {
    const [taskId, id] = context.queryKey;
    const result = await getApi().get(`/tasks/${taskId}/indicators/${id}`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [taskId, id, 'GET_DetailIndicatorVariables'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...config
  });
};

export const useDeleteIndicator = (options = {}) =>
  useMutation(
    ({ taskId, id }) =>
      getApi()
        .delete(`/tasks/${taskId}/indicators/${id}`)
        .then(axiosResult => axiosResult.data),
    options
  );
