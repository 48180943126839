import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, Typography, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { FormulationActivityItem } from './FormulationActivityItem';
import { palette } from '../../settings/themes/colors';
import { replaceStrings } from '../../utils/utils';
import { useGetProject } from '../../queries/queryProjects';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../utils/auth';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { TableIndicator } from './TableIndicator';

const styles = makeStyles({
  description: {
    color: palette.dimGray
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: lightThemeV2.spacing(2)
  }
});

const useFormulationActivityList = () => styles();

const FormulationActivityListComp = ({ activities = [], level, onSelected = () => {}, onUpdate = () => {}, readOnly, match, clientId }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useFormulationActivityList();
  const [description, setDescription] = useState('');
  const { data: project, isLoading } = useGetProject({ clientId, projectId: match.params.projectId });

  const currency = !isLoading && project.main_contract && project.main_contract.currency;

  useEffect(() => {
    if (level.levelType === 'one') {
      setDescription(replaceStrings(intlMessages[`formulation.levelDescription.${level.levelType}`], [level.name]));
      return;
    }

    setDescription(replaceStrings(intlMessages[`formulation.levelDescription.${level.levelType}`], [level.name, level.parent.name]));
  }, [level.levelType, level.name]);

  const onHandlerSelected = selected => {
    onSelected(selected);
  };

  if (activities.length === 0) {
    return (
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="body2" className={classes.description}>
            {intlMessages['formulation.whatIs'].replace('{1}', level.name)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
        </Grid>
      </Grid>
    );
  }

  if (isLoading) {
    return <div></div>;
  }

  return (
    <List>
      {activities.map(({ id, nestedPositions, ...activity }) => (
        <ListItem
          className={classes.container}
          onClick={() => onHandlerSelected({ id, ...activity })}
          key={`formulation-activity-item-${id}`}
        >
          <FormulationActivityItem
            id={id}
            {...activity}
            currency={currency}
            title={`${level.name} ${nestedPositions}`}
            onUpdate={() => onUpdate({ id, ...activity })}
            readOnly={readOnly}
          >
            <TableIndicator indicators={activity.indicators || []} projectId={match.params.projectId} taskId={id} readOnly={readOnly} />
          </FormulationActivityItem>
        </ListItem>
      ))}
    </List>
  );
};

FormulationActivityListComp.propTypes = {
  level: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number,
    levelAliasId: PropTypes.number,
    levelType: PropTypes.string.isRequired,
    parent: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  onSelected: PropTypes.func,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      budget: PropTypes.number,
      plannedToStartAt: PropTypes.string,
      plannedToCompleteAt: PropTypes.string,
      responsible: PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string
      })
    })
  ),
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string
    })
  }),
  clientId: PropTypes.number
};

export const FormulationActivityList = connect(state => ({ clientId: getCurrentUser(state).client_id }))(
  withRouter(FormulationActivityListComp)
);
